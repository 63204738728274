<template>
  <van-list v-model="loading" :finished="finished" loading-text="努力加载中" @load="onLoad">
    <div v-for="groupedListItem in list" :key="groupedListItem.title">
      <div class="grouped-list-title">{{ groupedListItem.title }}</div>
      <div v-for="item in groupedListItem.items" :key="item.id" class="card" @click="onClick(item)">
        <div class="card-header">
          <span>{{ formatTime(item.execDate) }}</span>
          <span>
                    {{ item.ratingAvg }}
                    <van-icon name="star" color="var(--secondMainColor)"/>
                </span>
          <img class="weixin_icon" :src="svgWeiXinIcon" @click.stop="shareRecordToWeChatMiniProgram(item)"/>
        </div>

        <div class="tags">
          <van-tag v-for="tag in item.mode" :key="tag" plain round color="var(--titleColor)">
            {{ tag }}
          </van-tag>
        </div>
        <div class="detail">{{ item.detail }}</div>

        <div class="card-footer">
          <span>{{ item.stage === 2 ? "一阶段" : item.stage === 3 ? "二阶段" : "" }}</span>
          <div>
            <span>{{ item.operateUserName }}</span>
            <span style="margin-left: 7px">{{ dayjs(item.updatedTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
          </div>
        </div>
      </div>

    </div>
    <van-empty v-if="list.length == 0 && finished"/>
  </van-list>
</template>

<script>
import dayjs from "dayjs";
import {queryRecordListGrouped} from "@/api/hoss";
import SvgWeiXinIcon from '@/assets/images/svg_weixin_icon.svg';
import {shareRecordToWXMiniGram} from "@/utils/nativeMethods";

export default {
  data() {
    return {
      loading: false,
      finished: false,
      svgWeiXinIcon: SvgWeiXinIcon,
      list: [],
      page: 0,
    };
  },
  created() {
    const {clientId} = this.$route.query;
    this.clientId = clientId;
  },
  methods: {
    dayjs,
    async getRecordList() {
      const {clientId, page} = this;
      const {value} = await queryRecordListGrouped({clientId, page});
      this.list.push(...value);
      this.finished = true;
      this.loading = false;
    },
    onLoad() {
      this.page++;
      this.getRecordList();
    },
    formatTime(t) {
      return dayjs(t).format("YYYY-MM-DD");
    },
    onClick({id}) {
      // 当前时间戳
      const now = new Date().getTime();
      this.$router.push(`/hoss/implementation?id=${id}&date=${now}`);
    },
    shareRecordToWeChatMiniProgram(item) {
      console.log('shareRecordToWeChatMiniProgram', item);
      shareRecordToWXMiniGram(this.clientId, item.execDate);
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  font-size: 12px;
  background-color: var(--blueCardBackgroundColor);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .weixin_icon {
    width: 0.7rem;
    height: 0.7rem;
  }

  .tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 3px;

    .van-tag {
      font-size: 8px;
      margin: 2px 5px;
      margin-left: 0;
    }
  }

  .detail {
    color: var(--subTitleColor);
    line-height: 1.3em;
    height: 2.6em;
    margin: 5px 0;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--mainColor);
  }

}

.loading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  span {
    margin-right: 10px;
  }
}

.van-empty {
  width: 180px;
  height: 180px;
  margin: 0 auto;
}

.grouped-list-title {
  font-size: 14px;
  color: var(--titleColor);
  margin-bottom: 10px;
  font-weight: bold;
}
</style>
